import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

type BlogCardProps = {
  title: string;
  date: string;
  excerpt: string;
  slug: string;
  featuredImage?: any;
  tags?: string[];
  readingTime: string;
  path: string;
};

export const BlogCard: React.FC<BlogCardProps> = ({
  date,
  excerpt,
  title,
  featuredImage,
  tags,
  path,
  readingTime,
}) => {
  return (
    <Link to={path}>
      <article
        className="rounded overflow-hidden shadow-md bg-white my-5 text-gray-900"
        style={{ width: 330, height: 440 }}
      >
        {featuredImage && (
          <GatsbyImage
            image={featuredImage}
            className="w-full h-48 object-fit"
            alt="Sunset in the mountains"
          />
        )}
        <div className="px-6 py-4 border-t-2 border-gray-300">
          <div className="flex flex-col mb-5">
            <span className="font-bold text-lg hover:text-indigo-500">
              {title}
            </span>
            <div className="flex">
              <small
                className="text-gray-700 mt-1"
                style={{ paddingBottom: 1 }}
              >
                {date}
              </small>
              <small
                className="text-gray-700 ml-auto"
                style={{ paddingBottom: 1 }}
              >
                ~ {readingTime} read
              </small>
            </div>
          </div>
          <p>{excerpt}</p>
        </div>
        {tags && (
          <div className="px-6 py-4">
            {tags.map((tag) => (
              <span
                key={tag}
                className="inline-block bg-purple-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2"
              >
                #{tag}
              </span>
            ))}
          </div>
        )}
      </article>
    </Link>
  );
};
