import { GlobalThemeConfig } from "types";

export const GLOBAL_THEME_CONFIG: GlobalThemeConfig = {
  dark: {
    link: "text-pink-400",
    linkHover: "hover:text-white",
    text: "text-white",
  },
  light: {
    link: "text-indigo-800",
    linkHover: "hover:text-indigo-600",
    text: "text-gray-900",
  },
};
