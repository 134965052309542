import React from "react";
import { ThemeOption } from "types";
import classNames from "classnames";
import { GLOBAL_THEME_CONFIG } from "theme/config";

export const Footer: React.FC<{
  theme: ThemeOption;
}> = ({ theme }) => {
  const today = new Date();
  const thisYear = today.getFullYear();
  return (
    <div className="w-full flex justify-center mb-1">
      <small
        className={classNames({
          [GLOBAL_THEME_CONFIG.dark.text]: theme === "dark",
          [GLOBAL_THEME_CONFIG.light.text]: theme === "light",
        })}
      >
        Copyright © {thisYear} Michael Hueter. All rights reserved.
      </small>
    </div>
  );
};
