import React, { useContext } from "react";
import { PageProps, PageThemeConfig, ThemeOption } from "types";
import { Nav } from "components/nav";
import { graphql } from "gatsby";
import classNames from "classnames";
import { DeepRequired } from "ts-essentials";
import { Page } from "components/page";
import { BlogCard } from "components/blog_card";
import { ThemeContext } from "context/theme";
import { FaRegFileAlt } from "react-icons/fa";
import { Footer } from "components/footer";
import { Main } from "components/main";

export const pageQuery = graphql`
  {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            readingTime
            path
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`;

const themeConfig: DeepRequired<PageThemeConfig> = {
  dark: {
    background: "bg-gray-800",
    textOnBackground: "text-white",
  },
  light: {
    background: "bg-white",
    textOnBackground: "text-gray-900",
  },
};

export const StyledP: React.FC<{ theme: ThemeOption }> = ({
  theme,
  children,
}) => {
  return (
    <p
      className={classNames("mt-8 mb-4 lg:max-w-xl", {
        [themeConfig.dark.textOnBackground]: theme === "dark",
        [themeConfig.light.textOnBackground]: theme === "light",
      })}
    >
      {children}
    </p>
  );
};

const CodingPage: React.FC<PageProps> = (props) => {
  const { data } = props;
  // const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMdx.edges;
  const { theme } = useContext(ThemeContext);

  return (
    <Page
      title="Coding"
      description="Coding events and recent blog posts"
      themeConfig={themeConfig}
    >
      <Nav />
      <Main theme={theme}>
        <div className="flex mt-8">
          <FaRegFileAlt className="text-3xl mr-3" />
          <h2
            className={classNames("text-2xl", {
              [themeConfig.dark.textOnBackground]: theme === "dark",
              [themeConfig.light.textOnBackground]: theme === "light",
            })}
          >
            Blog Posts
          </h2>
        </div>
        <StyledP theme={theme}>
          I write articles on a variety of tech-related topics including: web
          development, programming, and the tech industry.
        </StyledP>
        <div className="w-full flex flex-wrap ">
          {posts.map(({ node }) => {
            const featuredImgFluid =
              // @ts-expect-error types for gatsby image need updating
              node.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData;
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <div className="lg:w-1/2 flex" key={node.fields.slug}>
                <div className="md:mx-5 flex">
                  <BlogCard
                    title={title}
                    date={node.frontmatter.date}
                    excerpt={node.excerpt}
                    slug={node.fields.slug}
                    featuredImage={featuredImgFluid}
                    // tags={node.frontmatter.tags}
                    readingTime={node.frontmatter.readingTime}
                    path={node.frontmatter.path}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Main>
      <Footer theme={theme} />
    </Page>
  );
};

export default CodingPage;
