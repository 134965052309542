import React, { useState, useContext } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { ThemeOption } from "types";
import { ThemeContext } from "context/theme";
import { IoIosSunny, IoIosMoon } from "react-icons/io";

const SunAndMoon: React.FC<{
  theme: ThemeOption;
  turnLightModeOn(): void;
  turnDarkModeOn(): void;
}> = ({ theme, turnLightModeOn, turnDarkModeOn }) => {
  return (
    <div
      className={classNames(
        "absolute right-0 top-0 md:mr-5 flex items-center pt-1 focus:outline-none",
        {
          "text-white": theme === "dark",
          "hover:text-yellow-200": theme === "dark",
          "text-orange-500": theme === "light",
          "hover:text-orange-400": theme === "light",
        }
      )}
      role="button"
      tabIndex={1}
      onClick={() => (theme === "dark" ? turnLightModeOn() : turnDarkModeOn())}
      onKeyPress={(e) =>
        e.key === "Enter" &&
        (theme === "dark" ? turnLightModeOn() : turnDarkModeOn())
      }
    >
      <small className={classNames("cursor-pointer", "mr-1")}>
        {theme === "dark" ? "night" : "day"}
      </small>
      {theme === "dark" ? (
        <IoIosMoon
          className="text-xl cursor-pointer"
          title="Toggle light mode"
          style={{ marginBottom: 2 }}
        />
      ) : (
        <IoIosSunny
          className="text-xl cursor-pointer"
          title="Toggle dark mode"
        />
      )}
    </div>
  );
};

const BurgerMenu: React.FC<{ onClick(): void; theme: ThemeOption }> = ({
  onClick,
  theme,
}) => {
  return (
    <div className="block md:hidden" onClick={onClick}>
      <button
        className={classNames("flex items-center px-3 py-2 border rounded", {
          "text-white": theme === "dark",
          "border-white": theme === "dark",
          "border-black": theme === "light",
        })}
      >
        <svg
          className="fill-current h-3 w-3"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>
  );
};

const ClickOffDiv: React.FC<{ onClick(): void }> = ({ onClick }) => (
  <div
    className="top-0 left-0 bottom-0 right-0 z-10 flex fixed bg-gray-900 opacity-25"
    onClick={onClick}
  />
);

const NavLink: React.FC<{
  path: string;
  theme: ThemeOption;
  disabled?: boolean;
  className?: string;
}> = ({ children, path, theme, disabled, className }) => {
  return (
    <>
      <Link
        to={path}
        onClick={(e) => disabled && e.preventDefault()}
        data-tip={disabled ? "React-tooltip" : undefined}
        activeClassName={classNames("md:bg-transparent", {
          "md:border-pink-500": theme === "dark",
          "md:border-indigo-600": theme === "light",
          "bg-pink-500": theme === "dark",
          "bg-indigo-100": theme === "light",
        })}
        className={classNames(
          "block p-4 md:px-12 md:py-2 md:mt-0 md:inline-block tracking-wide border-transparent border-b-2 font-semibold md:mx-2",
          {
            "text-white": theme === "dark",
            "text-gray-900": theme === "light" && !disabled,
            "hover:indigo-400": theme === "light" && !disabled,
            "hover:border-indigo-600": theme === "light" && !disabled,
            "hover:border-pink-500": theme === "dark" && !disabled,
          },
          className
        )}
      >
        {children}
      </Link>
    </>
  );
};

export const Nav: React.FC = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const toggleMobileNav = () => setShowMobileNav(!showMobileNav);
  const { theme, turnDarkModeOn, turnLightModeOn } = useContext(ThemeContext);

  return (
    <>
      {showMobileNav && <ClickOffDiv onClick={() => setShowMobileNav(false)} />}
      <nav
        className="flex items-end justify-between flex-wrap m-6 z-20 absolute left-0 right-0"
        onClick={() => showMobileNav && setShowMobileNav(false)}
      >
        {/* <Brand theme={theme} /> */}
        <BurgerMenu onClick={toggleMobileNav} theme={theme} />
        <div className="w-full block flex-grow md:flex md:items-center md:w-auto">
          <div
            className={classNames(
              "mt-1 rounded p-0 md:bg-transparent text-md md:flex-grow justify-center md:flex shadow-lg md:shadow-none",
              {
                hidden: !showMobileNav,
                "bg-black": theme === "dark",
                "bg-white": theme === "light",
              }
            )}
          >
            <NavLink theme={theme} path="/" className="rounded-t">
              Home
            </NavLink>
            <NavLink theme={theme} path="/coding/">
              Coding
            </NavLink>
            <NavLink
              theme={theme}
              path="/about/"
              className="rounded-b md:rounded-b-none"
            >
              About
            </NavLink>
          </div>
        </div>
        <SunAndMoon
          theme={theme}
          turnDarkModeOn={turnDarkModeOn}
          turnLightModeOn={turnLightModeOn}
        />
      </nav>
    </>
  );
};
