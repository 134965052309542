import React, { useContext } from "react";
import classNames from "classnames";
import { PageThemeConfig } from "types";
import { ThemeContext } from "context/theme";
import { GLOBAL_THEME_CONFIG } from "theme/config";
import { SEO } from "./seo";

type PageComponentProps = {
  themeConfig: PageThemeConfig;
  title: string;
  description?: string;
  className?: string;
  siteTitleOverride?: string;
};

export const Page: React.FC<PageComponentProps> = ({
  themeConfig,
  title,
  siteTitleOverride,
  description,
  className,
  children,
}) => {
  const { theme } = useContext(ThemeContext);
  return (
    <>
      <SEO
        title={title}
        description={description}
        siteTitleOverride={siteTitleOverride}
      />
      <div
        className={classNames(
          "flex flex-col min-h-screen relative p-0",
          {
            [themeConfig.dark.background]: theme === "dark",
            [themeConfig.light.background]: theme === "light",
            [GLOBAL_THEME_CONFIG.dark.text]: theme === "dark",
            [GLOBAL_THEME_CONFIG.light.text]: theme === "light",
          },
          className
        )}
      >
        {children}
      </div>
    </>
  );
};
