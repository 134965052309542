import React from "react";
import classNames from "classnames";
import { ThemeOption } from "types";

type MainProps = { theme: ThemeOption; className?: string };

export const Main: React.FC<MainProps> = ({ children, theme, className }) => {
  return (
    <main
      className={classNames(
        "flex flex-col mt-24 lg:mt-20 mb-12 mx-auto pb-10 px-6 lg:px-10 lg:pt-10 main-container",
        {
          "text-white": theme === "dark",
        },
        className
      )}
    >
      {children}
    </main>
  );
};
